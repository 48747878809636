<template>
  <div class="changes-page">
    <div class="card">
      <el-row
        :gutter="20"
        style="min-height: 400px"
        v-loading="loading"
        element-loading-background="rgba(0, 0, 0, 0)"
      >
        <el-col :span="4" v-for="c in changeList" :key="c.id">
          <el-card shadow="hover">
            <img :src="c.thumbImageUrl" class="image" />
            <div class="card-content">
              <div class="card-content--title" :title="c.name">
                {{ c.name }}
              </div>
              <div class="card-content--title-tip">
                <div>
                  {{ $t("gold") }}:<span class="color-orange"
                    >{{ c.displayPoints }}{{ $t("ge") }}</span
                  >
                </div>
                <div>
                  {{ $t("remains") }}:<span class="color-orange"
                    >{{ c.stock }}{{ $t("ge") }}</span
                  >
                </div>
              </div>
              <el-button type="primary" size="small" round @click="open(c)">{{
                $t("exchange")
              }}</el-button>
            </div>
          </el-card>
        </el-col>
      </el-row>
      <el-pagination
        class="mt-20"
        background
        layout="prev, pager, next,total"
        :total="pagination.totalSize"
        :page-size="pagination.pageSize"
        :current-page="pagination.currentPage"
        @current-change="changePage"
      >
      </el-pagination>
    </div>
    <!-- Dialog -->
    <el-dialog
      title="提示"
      :visible.sync="dialogVisibleWX"
      class="exchange-dialog"
      width="800px"
    >
      <span slot="title" style="background:#ffa200;">{{ $t("tip") }}</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisibleWX = false">{{
          $t("cancel")
        }}</el-button>
        <el-button type="primary" @click="change()" :loading="changeLoading">{{
          $t("change.tip1")
        }}</el-button>
      </span>
      <div class="text-black-lighttext-md">{{ $t("change.tip2") }}</div>
      <div class="text-grey text-md">{{ $t("change.tip3") }}</div>
      <div class="text-grey text-md">
        {{ $t("change.tip4") }}
      </div>
      <div class="text-grey text-md">{{ $t("change.tip5") }}</div>
      <div v-html="currChange.describe"></div>
      <img :src="qrcode" alt="" />
      <div class="text-grey text-md">
        {{ $t("change.tip6") }}
      </div>
    </el-dialog>
    <!-- Dialog -->
    <el-dialog
      :title="$t('tip')"
      :visible.sync="dialogVisibleZFB"
      class="exchange-dialog"
      width="600px"
    >
      <span slot="title" style="background:#ffa200;">{{ $t("tip") }}</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisibleZFB = false">{{
          $t("cancel")
        }}</el-button>
        <el-button @click="navigate('/center/setting')">{{
          $t("update_zfb")
        }}</el-button>
        <el-button type="primary" @click="change()" :loading="changeLoading">{{
          $t("update_zfb_confirm")
        }}</el-button>
      </span>
      <div class="text-black-lighttext-md">
        {{ $t("update_zfb_confirm_tip1") }}
      </div>
      <div class="text-grey text-md">
        {{ $t("zfb_account") }}: {{ account.alipay || $t("not_fill") }}
      </div>
      <div class="text-grey text-md">
        {{ $t("name") }}: {{ userInfo.name || account.userName }}
      </div>
    </el-dialog>
  </div>
</template>
<style src="./index.scss" lang="scss"></style>
<script>
import qrcode from "assets/images/erw.jpg";
import { mapGetters, mapActions } from "vuex";
import { isEmpty } from "lodash-es";
import commonmixin from "../../../mixin/common.mixin";

export default {
  data() {
    return {
      dialogVisibleWX: false,
      dialogVisibleZFB: false,
      changeList: [],
      qrcode,
      currChange: {},
      changeLoading: false,
      loading: false,
      pagination: {
        pageSize: 15,
        totalSize: 0,
        currentPage: 1
      }
    };
  },
  mixins: [commonmixin],
  watch: {
    userInfo: "checkUserInfo"
  },
  async created() {
    this.checkUserInfo();
    this.getList();
  },
  mounted() {
    if (this.account && this.account.isBlacklist) {
      return this.$router.push(
        { path: "/" },
        () => {},
        () => {}
      );
    }
  },
  methods: {
    ...mapActions(["setDialogVisible"]),
    checkUserInfo() {
      if (
        !isEmpty(this.userInfo) &&
        (!this.userInfo.birthday || !this.userInfo.gender)
      ) {
        this.$message.warning(this.$t("info_tip1"));
        return this.$router.push({
          path: "/center/setting",
          query: { tab: "user" }
        });
      }
    },
    changePage(v) {
      this.getList(v);
    },
    async getList(v) {
      this.loading = true;
      const res = await this.$http.get(`/api/v1/Gifts`, {
        params: {
          page: v || this.pagination.currentPage,
          size: this.pagination.pageSize
        }
      });
      this.changeList = res.data.list;
      this.pagination = res.data.pagebar;
      this.loading = false;
    },
    handleClose() {},
    open(c) {
      if (!this.account.id) {
        return this.setDialogVisible({ login: true });
      }
      this.currChange = c;
      if (c.categoryId === 2) {
        this.dialogVisibleWX = true;
      } else if (c.categoryId === 1) {
        this.dialogVisibleZFB = true;
      }
    },
    async change() {
      this.changeLoading = true;
      const { status, statusText } = await this.$http.post(
        `/api/v1/Order`,
        null,
        {
          params: {
            id: this.account.id,
            giftID: this.currChange.id
          }
        }
      );
      if (status === 200) {
        this.$message.success(this.$t("exchange_success"));
        this.$router.push("/center/exchange-record");
      } else {
        this.$message.error(statusText);
      }
      this.changeLoading = false;
    }
  },
  computed: {
    ...mapGetters(["account", "userInfo"])
  }
};
</script>
